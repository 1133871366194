.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;

  > div {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .selected {
    display: flex;
    box-sizing: border-box;
    position: relative;
    padding: 0.5rem;
    border-radius: .5rem;

    &:has(.controls-row) {
      padding-bottom: 0.25rem;
    }

    .preview-spinner__container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .preview-spinner {
      height: inherit !important;
      display: flex;

      svg {
        background-color: transparent;
      }
    }

    .options {
      display: flex;
      align-items: center;
      gap: .2rem;
    }

    svg {
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .not-selected {
    color: white;
    border: unset;
    box-sizing: border-box;
    width: 150px;
    height: 105px;
    padding: 0.5rem;
    background-color: black;
    border-radius: 0.5rem;

    svg {
      width: 2rem;
      height: 2rem;
    }

    &:hover {
      cursor: pointer;

      svg {
        opacity: 0.5;
      }
    }
  }

  .player-progress {
    //padding: 0.5em 0 1em 0;

    .rc-slider-handle {
      background-color: var(--primary-color);
      border: 2px solid var(--primary-color);
    }

    .rc-slider-track {
      height: 4px;
      background-color: var(--primary-color);
    }
  }

  .controls-row {
    display: flex;

    .player-progress-container {
      flex: 1;
      padding-inline: 0.2em;
    }
  }
}

video.fake-pip {
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
}
