@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --bg-color: #06020A;
  --bg-surface-0: #1D1A21;
  --bg-surface-50: #2F2B36;
  --bg-surface-100: #524B5F;
  --bg-surface-150: #696177;
  --bg-surface-200: #7c738b;
  --fc-color: white;
  --primary-color: rgb(168, 54, 255);
  --primary-btn-fc: white;
  --default-btn-bg-color: lightgray;
  --default-btn-fc: black;
  --danger-btn-bg: #e43c33;
  --danger-btn-fc: white;
}

* {
  font-family: "Work Sans",
    "Poppins",
    Arial,
    Helvetica,
    sans-serif;
  color: var(--fc-color);
}

body {
  max-width: 85vw;
  margin: 0 auto;
  padding: 0;
  background-color: var(--bg-color);
  font-size: 1rem;

  @media (max-width: 1920px) {
    max-width: 90vw;
  }

  @media (max-width: 1440px) {
    max-width: 98vw;
  }
}

.btn {
  cursor: pointer;
  padding: 1rem 1.5rem;
  background-color: transparent;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
  border: none;
  border-radius: 2rem;
  filter: saturate(60%);
  transition: all .5s ease-in-out;

  &:hover,
  &:active:focus {
    filter: unset;
  }

  &:disabled {
    filter: saturate(30%), grayscale(50%);
  }
}

.btn-sm {
  padding: .25rem .5rem;
  border-radius: .5rem;
}

.btn-primary {
  border: solid 2px var(--primary-color);
  color: var(--primary-btn-fc);
  text-transform: capitalize;

  &:active:focus,
  &:hover {
    filter: unset;
    background-color: var(--primary-color);
  }

  &:disabled {
    filter: unset;
    cursor: not-allowed;
  }
}

.btn-remove {
  background-color: var(--danger-btn-bg);
  border: none;
  color: var(--danger-btn-fc);

  &:hover,
  &:active:focus,
  &:disabled:hover {
    background-color: var(--danger-btn-bg);
    border: none;
  }
}

.btn-grey {
  font-weight: 400;
  background-color: transparent;
  color: var(--primary-btn-fc);
  border: solid 2px var(--bg-surface-200);

  &:hover,
  &:active:focus,
  &:disabled:hover {
    background-color: var(--primary-btn-fc);
    border: solid 2px var(--primary-btn-fc);
    color: var(--bg-surface-0);
  }
}

.react-confirm-alert-overlay {
  background-color: var(--bg-color);
}

.react-confirm-alert-body {
  background-color: var(--bg-surface-50);
  color: var(--fc-color);
  font-family: "Work Sans", "Poppins", Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  line-height: 2rem;
  width: 26rem;
  padding: 3rem 5rem;
  text-align: center;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  h1{
    font-size: 2.5rem;
  }

  @media (max-width: 1024px) {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 24rem;
    padding: 2.5rem 4rem;
    h1{
      font-size: 1.75rem;
    }
  }
}

.react-confirm-alert-button-group {
  flex-direction: column;

  button {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1rem 3rem;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 600;
    color: var(--fc-color);
    line-height: 1.5rem;
    border-radius: 2rem;
    border: solid 2px var(--primary-color);
    margin-top: 1rem;
    transition: all .5s ease-in-out;
  
    @media (max-width: 1024px) {
      font-size: 0.875rem;
      padding: 0.75rem 2.5rem;
      margin-top: 0.5rem;
    }
  
    &:active:focus,
    &:hover {
      background-color: var(--primary-color);
      
    }
    
    &.white {
      background-color: #fff;
      color: #000;
      border: 2px solid #fff;
      
      .button-icon {
        fill: #000;
      }

      &:hover {
        background-color: var(--primary-color);
        color: #fff;
        border: solid 2px var(--primary-color);
        
        .button-icon {
          fill: #fff;
        }
      }
    }
  }
}

.button-icon {
  margin-right: 1rem;
  color: inherit;
}

.btn-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.show {
  display: block;
}

.accordion {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
  
  .accordion-header {
    padding: 1.25rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1.25rem;
    border: none;
    min-width: 45rem;
    background-color: var(--bg-surface-50);
    border-radius: 1rem;
    cursor: pointer;
    
    &__toggle {
      margin-left: auto;
    }
  }
  .accordion-body-container {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    height: 0;
    background-color: inherit;
    background-color: var(--bg-surface-50);
    border-radius: 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1rem 1.5rem;
    padding-top: 0;
    font-size: 1.25rem;

    z-index: 99999;

    dt {
      font-weight: 500;
      margin-bottom: .5rem;
    }

    dd {
      font-weight: 300;
      margin-bottom: 1rem;
    }
  }

  .accordion-body {
    border-top: 2px solid var(--bg-surface-150);
    padding-top: 1rem;
    margin: 0;
  }
  
  &.show {
    overflow: visible;
    .accordion-header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      
      &__toggle {
        transform: rotate(180deg);
      }
    }
    .accordion-body-container {
      height: auto;
    }
  }
}

.italic {
  font-style: italic;
}

.link {
  background: none;
  border: none;
  text-decoration: underline;
  font-size: 1.25rem;
  font-style: inherit;
  padding: 0;
}