#modal-edit-clip {
  margin-top: 5rem;
}

.loading {
  min-height: 30vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  svg {
    width: 6em;
  }

  .medialibrary-spinner {
    margin: 0 auto;
  }
}

.modal-body {
  >.row,
  >.table {
    margin: 0.5em 0;
  }
}

.player {
  margin-bottom: 2rem;
  .iframe {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    #player {
      width: 100%;
      min-height: 12rem;
      border: none;
      max-height: 700px;

      video {
        max-height: inherit;
      }
    }

    .range {
      margin: 0;
      padding: 1rem 1.5rem;
      background-color: var(--bg-surface-50);
      border-radius: 0.5rem;
    }

    .player-slicer {
      .rc-slider-handle {
        transform: translateX(-50%) translateY(50%) !important;
        border-radius: 1em;
        width: 0.5rem;
        height: 1.5rem;
        border: 2px solid var(--primary-color);
        background-color: var(--primary-color);
        top: -0.25rem;
      }

      .rc-slider-track {
        background-color: white;
        height: 20px;
        border-radius: 0.2em;
        border: 2px solid white;
      }
    }

    .control-bar {
      display: flex;
      padding: 10px;

      &:nth-child(n) {
        color: white;
      }

      svg {
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }

  .no-encoded {
    text-align: center;
    background-color: lightgrey;
    border: 2px dotted grey;
  }
}

.clip-info {
  display: flex;
  justify-content: space-around;
  padding: 2rem 1.5rem;
  background-color: var(--bg-surface-50);
  border-radius: 0.5rem;

  @media (max-width: 1200px) {
    display: grid;
    grid-template-areas:
      "clip-start clip-end"
      "clip-cut clip-cut"
    ; 
  }

  .start{
    grid-area: clip-start;
  }

  .end{
    grid-area: clip-end;
  }

  .clip-cut{
    grid-area: clip-cut;
    
    @media (max-width: 1200px) {
      margin-top: 2rem;
    }
  }

  .clip-col{
    border: solid 1px var(--bg-surface-100);
    border-radius: 0.5rem;
    padding: 1rem;
  }


  .clip-action {
    min-height: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin: auto 1.5rem;

    .set {
      padding: 0.75rem 1.75rem;
      border-radius: 0.5rem;

      &:hover {
        cursor: pointer;
        font-weight: 500;
      }
    }

    .cut {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      font-size: 1rem;
      padding: 1.25rem 2.5rem;
      border-radius: 2.5rem;
      min-width: 10rem;
      border: 2px solid var(--default-btn-bg-color);

      &:hover {
        cursor: pointer;
        color: white;
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);

        &:disabled {
          cursor: not-allowed;
          color: rgb(128, 128, 128, 0.5);
          background-color: rgb(211, 211, 211, 0.1);
          border: 2px solid var(--default-btn-bg-color);
        }
      }

      svg {
        align-self: center;
        margin-right: 1rem;
      }
    }

    &__alert {
      display: flex;
      align-items: center;
      background: var(--bg-surface-100);
      border-radius: 0.5rem;
      padding: 1rem;

      &__icon {
        flex-shrink: 0;
        margin-right: .5rem;
      }
    }
  }

  .change-time {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0.5em 0;
    border-top: solid 1px var(--bg-surface-100);
    padding-top: 0.5rem;

    .back-forward {
      position: relative;
      color: grey;
      width: 2.25rem;
      height: 2rem;
      background-color: var(--bg-surface-100);
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      transition: all .2s;

      &:hover {
        cursor: pointer;
        color: black;
        background-color: var(--bg-surface-0);
      }

      svg {
        width: 1.675rem;
      }

      span {
        position: absolute;
        top: 24%;
        left: 35%;
        width: 50%;
      }
    }
  }

  .thumbnail {
    background-color: rgb(211, 211, 211, 0.4);
    position: relative;

    p {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0;
      z-index: -1;
    }
  }
}

.clip-list {
  position: relative;
  width: 100%;
  text-align: center;
  align-items: center;

  th,
  td {
    padding: 0.5em 0;
  }
  tbody{
    border-spacing: none;
    tr{
      background-color: var(--bg-surface-0);
    }
  }
}

.save {
  text-align: right;
  padding: 10px 0;
}


.tooling {
  display: grid;
  padding: 0 4rem;

  grid-template-areas:
      "title"
      "tool"
      "clips"
      "actions"
  ;

  /*
  grid-template-areas:
    "title   title"
    "tool    clips"
    "tool    actions"
  ;*/
  
  gap: 2rem;

  /*@media (max-width: 1250px) {
    grid-template-areas:
      "title"
      "tool"
      "clips"
      "actions"
    ;
  }*/

  .crop-tool {
    background-color: var(--bg-surface-0);
    border-radius: .5rem;
    padding: 2rem;
    grid-area: tool;
  }

  .clips-tool {
    padding: 1rem 0 0 0;
    border-top: solid 1px var(--fc-color);
    grid-area: clips;
  }

  .actions {
    grid-area: actions;
    display: flex;
    gap: 1rem;
    justify-content: center;

    .btn-save {
      padding: 1.25rem 2.25rem;
      width: fit-content;
      min-width: 15rem;
    }
  }
}

.title-container {
  display: flex;
  align-items: center;
  
  .title {
    padding-left: 1.25rem;
    border-left: 1px solid grey;
  }

  .arrow-back-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1.75rem;

    .arrow-back {
      background: transparent;
      display: flex;
      align-items: center;
      border: 1px solid #9e9e9e;
      border-radius: 4px;
      width: 3rem;
      height: 2.75rem;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: #595959;
        border: none;
      }
    }
  }

  .accordion-container {
    margin-left: auto;
  }

}